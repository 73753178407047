<template>
  <div>
    <GreenBlock class-name="green-section--absolute"/>
    <v-container class="container--margin">
      <div class="box-shadow">
        <h1 class="section__title">Vielen Dank!</h1>
        <div class="section__img" :style="{ backgroundImage: 'url(' + require('@/assets/images/family.jpg') + ')' }"></div>
        <p class="thanks-description">Vielen Dank für Ihre erneute Bestellung der eprimo Strompakete.<br>
          Wir schreiben Ihrem Konto in Kürze das neue Paketvolumen gut.</p>
        <h2 class="section__title">Nächste Schritte</h2>
        <div class="function-track">
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon1.svg" alt="">
              <span class="count-bg"><span class="count-number">1</span></span>
            </div>
            <div>
              <p>Sie erhalten von uns eine Bestätigung für Ihr neues Strompaket.</p>
            </div>
          </div>
          <div class="function-track__item--small">
            <div class="epicon epicon-arrow-right"></div>
          </div>
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon3.svg" alt="">
              <span class="count-bg"><span class="count-number">2</span></span>
            </div>
            <div>
              <p>Verbleibende Restmengen aus Ihrem vorherigen Strompaket bleiben selbstverständlich erhalten.</p>
            </div>
          </div>
          <div class="function-track__item--small">
            <div class="epicon epicon-arrow-right"></div>
          </div>
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/post.svg" alt="">
              <span class="count-bg"><span class="count-number">3</span></span>
            </div>
            <div>
              <p>Verfolgen Sie den Verlauf Ihres Stromverbrauchs auf dem Dashboard.</p>
            </div>
          </div>
        </div>
        <div v-if="token">
          <hr>
          <v-row class="section__bottom">
            <v-col sm="9">
              <v-col sm="8" class="py-0 px-0">
                <h2 class="section__title">Noch mehr Komfort und Übersicht <br> mit dem Smart Meter </h2>
              </v-col>
              <v-col sm="11" class="py-0 px-0">
                <p>Auf Wunsch erhalten Sie zusätzlich einen intelligenten Stromzähler (inkl. Einbau*).
                  Dieser ist automatisch mit der dazugehörigen App auf Ihrem Smartphone verbunden
                  und liefert Ihnen einen jederzeit aktuellen Blick auf Ihre bisherigen Verbrauchsdaten.</p>
              </v-col>
              <ul class="list-checkmark ">
                <li>Immer den vollen Überblick behalten, wie lange Ihr Paket noch reicht</li>
                <li>Keine manuelle Meldung von Zählerständen notwendig</li>
                <li>Automatische Benachrichtigung vor dem Aufbrauchen der Paketguthaben</li>
                <li>Zugang zu weiteren Aktionen und Sonderangeboten</li>
              </ul>
            </v-col>
            <v-col sm="3" class="text-center">
              <img src="@/assets/images/icons/smartmeter.svg" alt="smartmeter">
            </v-col>
          </v-row>
          <div class="text-center">
            <a class="btn mb-5 mt-4 home-btn" :href="`${publicPath}smartmeter/${token}`" target="_blank"><span @click="updateSmartMeter">Smart Meter bestellen</span></a>
          </div>
        </div>
        <div v-else>
          <div class="text-center">
            <a class="btn mb-5 mt-4 home-btn" href="https://www.eprimo.de/service/account/auth/login" >Mein eprimo besuchen</a>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: "ThankYouNewOffer",
  components: {
    GreenBlock
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      token: ''
    }
  },
  mounted() {
    this.token = this.$cookies.get('token');
  },
  methods: {
    updateSmartMeter() {
      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/submit/' + this.$cookies.get('uuid'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Smart_Meter_Request: 1})
      });
      this.$router.push('danke-smartmeter')
      this.$cookies.remove('uuid');
      this.$cookies.remove('token');
    }
  }
}
</script>

<style lang="scss" scoped>
.container--margin {
  margin-top: 15px;
}

.list-checkmark {
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;

  li {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.thanks-description {
  line-height: 25px;
}

.section__img {
  height: 35vw;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
}

a.home-btn {
  min-width: 300px;
}

.function-track {
  margin-top: 25px;

  &__block {
    margin: 30px 0 50px;
  }

  &__circle {
    width: 70px;
    height: 70px;
    border: 2px solid $green;
    background-color: $white;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .count {
    &-bg {
      position: absolute;
      bottom: -10px;
      right: -5px;
      padding: 2px;
      width: 30px;
      height: 30px;
      color: $white;
      background: $green;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 26px;
    }

    &-number {
      display: block;
      color: $white;
      border-radius: 50%;
      font-weight: 700;
      text-align: center;
      height: 100%;

      &:before {
        color: white;
        font-size: 23px;
        line-height: 40px;
      }
    }
  }

  &__item {
    font-size: 12px;
    padding: 0 10px;
  }

  .epicon {
    color: $green;
  }
}

.section__bottom {
  align-items: center;

  img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .function-track {
    text-align: center;

    &__item--small {
      margin: 10px;
    }

    .epicon {
      font-size: 45px;
      transform: rotate(90deg);
    }
  }

  a.home-btn {
    min-width: 240px;
  }

  .section__title br {
    display: none;
  }

  .section__bottom {
    .col {
      flex: none;
      max-width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .container--margin {
    margin-top: 50px;
  }

  .section__img {
    height: 320px;
  }

  .function-track {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 25px -5px 0;

    &__item {
      width: 24%;
      padding: 0;
      margin: 0 10px;

      &:first-of-type p {
        margin-left: 20px;
      }

      &--small {
        width: 30px;

        .epicon-arrow-right {
          font-size: 64px;
          margin-top: 20px;
          margin-left: -25px;
        }
      }
    }

    &__circle {
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
    }

    .count {
      &-bg {
        width: 40px;
        height: 40px;
        font-size: 25px;
        font-weight: 700;
        padding: 4px;
        line-height: 32px;
      }
    }
  }
}

</style>
